import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Types from "./types/types";

export class Api {
  axiosInstance: AxiosInstance;
  chatbot = {
    postMessage: (
      data: Types["chatbot"]["postMessage"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["chatbot"]["postMessage"]["output"]>
      >(`/api/chat-bot-message`, data, options),

    postChatbotChat: (
      data: Types["chatbot"]["postChatbotChat"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["chatbot"]["postChatbotChat"]["output"]>
      >(`/api/chat-bot-chat`, data, options),

    putChatbotChatId: (
      chatId: number,
      data: Types["chatbot"]["putChatbotChatId"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.put<
        any,
        AxiosResponse<Types["chatbot"]["putChatbotChatId"]["output"]>
      >(`/api/chat-bot-chat/` + chatId, data, options),

    deleteChatbotChatId: (chatId: number, options?: AxiosRequestConfig) =>
      this.axiosInstance.delete<
        any,
        AxiosResponse<Types["chatbot"]["deleteChatbotChatId"]["output"]>
      >(`/api/chat-bot-chat/` + chatId, options),

    getChatbotChat: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["chatbot"]["getChatbotChat"]["output"]>
      >(`/api/chat-bot-chat`, options),

    getChatbotChatId: (chatId: number, options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["chatbot"]["getChatbotChatId"]["output"]>
      >(`/api/chat-bot-chat/` + chatId, options),

    postChatbotEvent: (
      data: Types["chatbot"]["postChatbotEvent"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["chatbot"]["postChatbotEvent"]["output"]>
      >(`/api/chatbot-histories/postEvent`, data, options),

    getChatbotUserPhoneDataUserId: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<
          Types["chatbot"]["getChatbotUserPhoneDataUserId"]["output"]
        >
      >(`/api/chatbot-user-data/getChatbotUserPhoneData`, options),
  };

  rewards = {
    postCheckCodeReward: (
      data: Types["rewards"]["postCheckCodeReward"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["rewards"]["postCheckCodeReward"]["output"]>
      >(`/api/checkCodeReward`, data, options),
  };

  places = {
    getPlace: (id: string, options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["places"]["getPlace"]["output"]>
      >(`/api/place/${id}`, options),
    getAllPlacesPins: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["places"]["getAllPlacesPins"]["output"]>
      >(`/api/getAllPlacesPins`, options),
  };

  meetings = {
    getMeeting: (id: string, options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["meetings"]["getMeeting"]["output"]>
      >(`/api/getMeetingByUuid/${id}`, options),

    putApiMeetingsIdVote: (
      id: string,
      data: Types["meetings"]["putApiMeetingsIdVote"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.put<
        any,
        AxiosResponse<Types["meetings"]["putApiMeetingsIdVote"]["output"]>
      >(`/api/meetings/${id}/publicVote`, data, options),

    putApiMeetingsIdUpdateVotePhoneVoteId: (
      id: string,
      voteId: string,
      data: Types["meetings"]["putApiMeetingsIdUpdateVotePhoneVoteId"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.put<
        any,
        AxiosResponse<
          Types["meetings"]["putApiMeetingsIdUpdateVotePhoneVoteId"]["output"]
        >
      >(`/api/meetings/${id}/publicUpdateVotePhone/${voteId}`, data, options),
  };

  mailingPhone = {
    postRequestCodeToUnsubscribe: (
      data: Types["mailingPhone"]["requestCodeToUnsubscribe"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<
          Types["mailingPhone"]["requestCodeToUnsubscribe"]["output"]
        >
      >(`/api/requestCodeToUnsubscribe`, data, options),

    postUnsubscribe: (
      data: Types["mailingPhone"]["unsubscribe"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["mailingPhone"]["unsubscribe"]["output"]>
      >(`/api/unsubscribe`, data, options),
  };
  phoneAuth = {
    postApiPhoneAuthSms: (
      data: Types["phoneAuth"]["postApiPhoneAuthSms"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["phoneAuth"]["postApiPhoneAuthSms"]["output"]>
      >(`/api/phone-auth/sms`, data, options),

    postApiPhoneAuthSmsConfirm: (
      data: Types["phoneAuth"]["postApiPhoneAuthSmsConfirm"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<
          Types["phoneAuth"]["postApiPhoneAuthSmsConfirm"]["output"]
        >
      >(`/api/phone-auth/sms/confirm`, data, options),
  };

  authExt = {
    postApiAuthExtAuthProviderAuthStepStep: (
      provider: string,
      step: string,
      data: Types["authExt"]["postApiAuthExtAuthProviderAuthStepStep"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<
          Types["authExt"]["postApiAuthExtAuthProviderAuthStepStep"]["output"]
        >
      >(`/api/auth-ext/auth/${provider}/auth-step/${step}`, data, options),
  };

  user = {
    postUsersMeEmailLogin: (
      data: Types["user"]["usersMeEmailLogin"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["usersMeEmailLogin"]["output"]>
      >(`/api/users/me/emailLogin`, data, options),

    getApiUsersMe: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["user"]["getApiUsersMe"]["output"]>
      >(`/api/users/me`, options),

    postApiUsersMe: (
      data: Types["user"]["postApiUsersMe"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["postApiUsersMe"]["output"]>
      >(`/api/users/me`, data, options),

    postApiUsersDeleteMe: (
      data: Types["user"]["postApiUsersDeleteMe"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["postApiUsersDeleteMe"]["output"]>
      >(`/api/users/deleteMe`, data, options),

    postApiForgotPassword: (
      data: Types["user"]["postApiForgotPassword"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["postApiForgotPassword"]["output"]>
      >(`/api/auth/forgot-password`, data, options),

    postApiUsersMeSendConfirmEmail: (
      data: Types["user"]["postApiUsersMeSendConfirmEmail"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["postApiUsersMeSendConfirmEmail"]["output"]>
      >("/api/users/me/sendConfirmEmail", data, options),

    postApiAuthResetPassword: (
      data: Types["user"]["postApiAuthResetPassword"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["postApiAuthResetPassword"]["output"]>
      >(`/api/auth/reset-password`, data, options),

    postApiUsersCheckEmail: (
      data: Types["user"]["postApiUsersCheckEmail"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["user"]["postApiUsersCheckEmail"]["output"]>
      >("/api/users/checkEmail", data, options),

    getApiChatbotMyPayment: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["user"]["getApiChatbotMyPayment"]["output"]>
      >("/api/chatbot/get-my-payment", options),
  };

  place = {
    getApiPlaceGetAlsoLiked: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["place"]["getApiPlaceGetAlsoLiked"]["output"]>
      >(`/api/place/getAlsoLiked`, options),

    putApiPlaceSetUserStatusId: (
      id: string,
      data: Types["place"]["putApiPlaceSetUserStatusId"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.put<
        any,
        AxiosResponse<Types["place"]["putApiPlaceSetUserStatusId"]["output"]>
      >(`/api/place/setUserStatus/${id}`, data, options),

    getApiPlaceFavorite: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["place"]["getApiPlaceFavorite"]["output"]>
      >(`/api/place/favorite`, options),

    getApiPlaceId: (id: string, options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["place"]["getApiPlaceId"]["output"]>
      >(`/api/place/${id}`, options),

    getApiPlaceInstagramImagesId: (id: string, options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["place"]["getApiPlaceInstagramImagesId"]["output"]>
      >(`/api/place/findPlaceInstagramImages/${id}`, options),

    getApiPlace: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["place"]["getApiPlace"]["output"]>
      >(`/api/place`, options),

    getAllPlacesPins: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["place"]["getAllPlacesPins"]["output"]>
      >(`/api/getAllPlacesPins`, options),

    postAdvancesSearchPlaces: (
      data: Types["place"]["postAdvancesSearchPlaces"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["place"]["postAdvancesSearchPlaces"]["output"]>
      >(`/api/meilisearchFind`, data, options),

    postRecommendedSearchPlaces: (
      data: Types["place"]["postRecommendedSearchPlaces"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["place"]["postRecommendedSearchPlaces"]["output"]>
      >(`/api/recommendationSearch`, data, options),

    postSearchInFavourites: (
      data: Types["place"]["postSearchInFavourites"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["place"]["postSearchInFavourites"]["output"]>
      >(`/api/SearchInFavourites`, data, options),

    getBookingData: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["place"]["getBookingData"]["output"]>
      >(`/api/getBookingData`, options),
  };

  placelists = {
    getPlacelistHistory: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["placelists"]["getPlacelistHistory"]["output"]>
      >(`/api/placelist/getPlacelistHistory`, options),

    getPlacelist: (options?: AxiosRequestConfig) =>
      this.axiosInstance.get<
        any,
        AxiosResponse<Types["placelists"]["getPlacelist"]["output"]>
      >(`/api/placelist`, options),
  };

  bugReport = {
    postBugReport: (
      data: Types["bugReport"]["postBugReport"]["input"],
      options?: AxiosRequestConfig
    ) =>
      this.axiosInstance.post<
        any,
        AxiosResponse<Types["bugReport"]["postBugReport"]["output"]>
      >(`/api/bug-reports`, data, options),
  };

  constructor(instance: AxiosInstance = axios.create()) {
    this.axiosInstance = instance;
  }
}
