import { useNavigate } from "react-router-dom";
import useUniqueDispatch, {
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { RootState } from "../store/root-reducer";
import React, { useMemo, useState } from "react";
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Avatar, Day, GiftedChat, Time } from "react-native-gifted-chat";

import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { PlaceCardMessage } from "./PlaceCardMessage";
import { ReactComponent as SendButtonIcon } from "@gadder/common/src/assets/svg/send_button.svg";
import { useIsMobile } from "../pages/ChatPage";
import { UserAvatar } from "@gadder/common/src/components/user-avatar";
import { chatbotPostEventAction } from "../store/chatbot/action";

import CosyImage from "@gadder/common/src/assets/images/chatbot_init_option_cosy.jpg";
import WorkImage from "@gadder/common/src/assets/images/chatbot_init_option_great_to_work_from.jpg";
import DateImage from "@gadder/common/src/assets/images/chatbot_init_option_for_a_date.jpg";
import FriendsImage from "@gadder/common/src/assets/images/chatbot_init_option_go_out_with_friends.jpg";
import { ReactComponent as ResetIcon } from "@gadder/common/src/assets/svg/reset.svg";
import { PlaceCardMedium } from "@gadder/common/src/components/PlaceCardMedium";
import _ from "lodash";
import { Place_Place__component } from "@gadder/common/src/api/models/Place_Place__component";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";

const imagePreviews = [
  {
    url: CosyImage,
    label: "cosy",
    message: "cosy place",
  },
  {
    url: WorkImage,
    label: "great to work from",
    message: "place great to work from",
  },
  {
    url: DateImage,
    label: "for a date",
    message: "place for a date",
  },
  {
    url: FriendsImage,
    label: "go out with friends",
    message: "place to go out with friends",
  },
];

export const Chat = ({
  isLoading = false,
  isError,
  onChatClosing,
  chat,
  onSubmitMessage,
}: {
  isError: any;
  isLoading?: boolean;
  chat?: any;
  onSubmitMessage: (chat_id: number, message: string) => void;
  onChatClosing?: (chat_id: number) => void;
}) => {
  const user = useSelector((store: RootState) => store.user);

  const [messageText, setMessageText] = useState("");

  const submitMessage = () => {
    onSubmitMessage(chat.id, messageText);
    setMessageText("");
  };

  const isMobile = useIsMobile();

  const messagesList = useMemo(
    () =>
      chat && chat?.messages
        ? [
            ...chat?.messages
              ?.map((_message) => {
                const bookingTimes = _.keyBy(
                  _message.booking_times || [],
                  "place_id"
                );

                return {
                  _id: _message.id,
                  createdAt: _message.createdAt,
                  user: {
                    _id: _message.author === "user" ? user.data?.id : "bot",
                    avatar: () => (
                      <UserAvatar
                        size={36}
                        user={
                          _message.author === "user" ? user.data : undefined
                        }
                      />
                    ),
                    name: _message.author,
                  },
                  bookingPlaces: _message.places?.reduce((accum, _place) => {
                    const bookingData = bookingTimes[_place.place_id];
                    if (bookingData) {
                      accum.push({
                        ..._place,
                        booking_dates: JSON.parse(bookingData.times),
                        book_provider: bookingData.provider,
                      });
                    }
                    return accum;
                  }, []),
                  places: _message.places?.filter(
                    (_place) => !bookingTimes[_place.place_id]
                  ),
                  text: _message.text,
                };
              })
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
          ]
        : [],
    [chat?.messages, isLoading]
  );

  return (
    <View style={{ flex: 1 }}>
      {chat && (
        <GiftedChat
          isTyping={isLoading}
          // renderLoading={<ActivityIndicator />}
          messages={messagesList}
          renderAvatar={(props) => (
            <Avatar
              {...props}
              position={"right"}
              containerStyle={{
                left: { marginRight: 0 },
                right: { marginLeft: 0 },
              }}
            />
          )}
          showUserAvatar
          user={{
            _id: user.data?.id,
            name: user.data?.nametag?.toLowerCase(),
          }}
          renderFooter={() => {
            return chat.messages?.length === 0 ? (
              <View style={{ alignItems: "center" }}>
                <Text
                  style={[
                    isMobile
                      ? fonts.mediumText_medium
                      : fonts.mediumLargeText_medium,
                    { color: colors.black, marginBottom: isMobile ? 16 : 28 },
                  ]}
                >
                  What's the plan?
                </Text>
                <View
                  style={{
                    alignSelf: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 20,
                    marginBottom: 40,
                    width: isMobile ? 300 : 460,
                  }}
                >
                  {imagePreviews.map((_item) => (
                    <TouchableOpacity
                      onPress={() => {
                        onSubmitMessage(chat.id, _item.message);
                      }}
                    >
                      <ImageBackground
                        source={{ uri: _item.url }}
                        style={{
                          width: isMobile ? 140 : 220,
                          aspectRatio: 1,
                          padding: 10,
                          backgroundColor: colors.black,
                          overflow: "hidden",
                          borderRadius: 12,
                          flexDirection: "row",
                          alignItems: "flex-end",
                        }}
                      >
                        <Text
                          style={[
                            isMobile
                              ? fonts.smallText_bold
                              : fonts.smallMediumText_bold,
                            { color: colors.white, flex: 1 },
                          ]}
                        >
                          {_item.label}
                        </Text>
                      </ImageBackground>
                    </TouchableOpacity>
                  ))}
                </View>
              </View>
            ) : isLoading ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 24,
                  alignSelf: "flex-start",
                  marginLeft: 8,
                }}
              >
                <UserAvatar size={36} />
                <ActivityIndicator
                  color={colors.black}
                  style={{ marginLeft: 8 }}
                />
              </View>
            ) : isError ? (
              <Text
                style={[
                  isMobile
                    ? fonts.smallMediumText_medium
                    : fonts.mediumText_medium,
                  {
                    color: colors.WarningRed,
                    flex: 1,
                    paddingHorizontal: 16,
                    paddingTop: 8,
                    paddingBottom: 32,
                  },
                ]}
              >
                Internal error, please repeat your request or type reset to
                start new session
              </Text>
            ) : null;
          }}
          renderBubble={(props) => {
            return (
              <View
                style={{
                  alignItems:
                    props.currentMessage?.user?._id === user.data?.id
                      ? "flex-end"
                      : "flex-start",
                  marginLeft:
                    props.currentMessage?.user?._id === user.data?.id ? 8 : 0,

                  marginRight:
                    props.currentMessage?.user?._id === user.data?.id ? 0 : 8,

                  maxWidth: "85%",
                }}
              >
                {props.currentMessage?.bookingPlaces && (
                  <PlacesList
                    places={props.currentMessage?.bookingPlaces}
                    chatId={chat.id}
                  />
                )}

                {props.currentMessage?.places &&
                  !props.currentMessage?.bookingPlaces.length && (
                    <PlacesList
                      places={props.currentMessage?.places}
                      chatId={chat.id}
                    />
                  )}

                {props.currentMessage?.text && (
                  <Text
                    style={[
                      isMobile
                        ? fonts.smallMediumText_medium
                        : fonts.mediumText_medium,
                      {
                        maxWidth: "100%",
                        color: colors.black,
                        marginBottom: 4,
                      },
                    ]}
                  >
                    {props.currentMessage?.places
                      ? props.currentMessage?.text.replace(
                          "\n🔄 Need to start over? Just type 'reset'.",
                          ""
                        )
                      : props.currentMessage?.text}
                  </Text>
                )}

                {props.currentMessage?.places &&
                  props.currentMessage?.bookingPlaces?.length > 0 && (
                    <PlacesList
                      places={props.currentMessage?.places}
                      chatId={chat.id}
                    />
                  )}

                {props.currentMessage?.places?.length > 0 &&
                  props.currentMessage?.bookingPlaces?.length > 0 && (
                    <Text
                      style={[
                        isMobile
                          ? fonts.smallMediumText_medium
                          : fonts.mediumText_medium,
                        {
                          maxWidth: "100%",
                          color: colors.black,
                          marginBottom: 4,
                        },
                      ]}
                    >
                      You should also take a look at these places, without
                      booking information.
                    </Text>
                  )}

                {messagesList[0]._id === props.currentMessage?._id &&
                  props.currentMessage?.places?.length > 0 && (
                    <ResetLine
                      onPress={() => onSubmitMessage(chat.id, "reset")}
                    />
                  )}

                <Time
                  position={"left"}
                  containerStyle={{ left: { marginLeft: 0, marginRight: 0 } }}
                  timeTextStyle={{
                    left: {
                      ...fonts.little_regular,
                      lineHeight: 10,
                    },
                  }}
                  currentMessage={props.currentMessage}
                />
              </View>
            );
          }}
          minInputToolbarHeight={65}
          renderInputToolbar={(props) => (
            <View style={[styles.footerContainer, { paddingBottom: 15 }]}>
              <TextInput
                onKeyPress={(event) => {
                  if (
                    (event.nativeEvent.key === "Enter" ||
                      event.nativeEvent.key === "NumpadEnter") &&
                    messageText
                  ) {
                    event.preventDefault();
                    submitMessage();
                  }
                }}
                placeholder={"Start chatting"}
                placeholderTextColor={colors.darkGray}
                style={[styles.textInput, fonts.smallMediumText_regular]}
                onChangeText={(text) => setMessageText(text)}
                value={messageText}
                keyboardType={"twitter"}
                multiline={true}
                spellCheck={false}
                autoCorrect={false}
              />

              <TouchableOpacity
                hitSlop={{ top: 32, left: 16, bottom: 32, right: 16 }}
                disabled={!messageText}
                onPress={submitMessage}
              >
                <SendButtonIcon style={{ marginLeft: 8 }} />
              </TouchableOpacity>
            </View>
          )}
          listViewProps={{
            disableVirtualization: true,
            onEndReachedThreshold: 0.9,
          }}
          // loadEarlier={
          //   messagesState?.data?.meta.total !== messagesState?.data?.data?.length
          // }
          // onLoadEarlier={() =>
          //   loadMessages([
          //     {
          //       params: {
          //         chat_id: chatId,
          //         pagination_start: messagesState?.data?.data?.length,
          //       },
          //     },
          //   ])
          // }
        />
      )}
    </View>
  );
};

const PlacesList = ({
  places,
  chatId,
}: {
  places: FullPlaceType[];
  chatId: number;
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [postChatEventToken, postChatEvent] = useUniqueDispatch(
    chatbotPostEventAction.request
  );

  const placeRoute = (place_id: number, booking_dates: string[] = []) => {
    postChatEvent([{ chat_id: chatId, type: "place_screen_visit", place_id }]);
    window.open(
      "/place/" +
        place_id +
        "?bookingTime=" +
        JSON.stringify(booking_dates) +
        "&chatId=" +
        chatId,
      "_blank"
    );
  };

  const mapRouteHandle = (placeId) => {
    navigate("/map?placeId=" + placeId);
  };

  return (
    <View
      style={
        isMobile
          ? {
              width: document.documentElement.offsetWidth,
              left: "calc(-52px)",
            }
          : {}
      }
    >
      <ScrollView
        horizontal={isMobile}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          paddingHorizontal: isMobile ? 52 : 0,
          flexDirection: "row",
          flexWrap: isMobile ? "nowrap" : "wrap",
        }}
      >
        {places.map((_place) => (
          <PlaceCardMedium
            style={{
              width: isMobile ? 260 : 280,
              marginTop: 8,
              marginBottom: 20,
              marginHorizontal: 14,
            }}
            onAddressPress={() => mapRouteHandle(_place.place_id)}
            onPress={() => placeRoute(_place.place_id, _place.booking_dates)}
            onArrowPress={() =>
              placeRoute(_place.place_id, _place.booking_dates)
            }
            place={_place}
          />
        ))}
      </ScrollView>
    </View>
  );
};

const ResetLine = ({ onPress }: { onPress: () => void }) => {
  const isMobile = useIsMobile();

  const [isHidden, setIsHidden] = useState(false);

  const pressHandle = () => {
    onPress();
    // setIsHidden(true);
  };

  return (
    <>
      {!isHidden && (
        <View
          style={{
            flexDirection: "row",
            paddingVertical: 4,
            alignItems: "center",
          }}
        >
          <Text
            style={[
              isMobile ? fonts.smallMediumText_medium : fonts.mediumText_medium,
              { color: colors.black },
            ]}
          >
            🔄 Need to start over? Just tap{" "}
          </Text>
          <TouchableOpacity
            onPress={pressHandle}
            style={{
              marginLeft: 4,
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 4,
              paddingHorizontal: 6,
              borderWidth: 1,
              borderColor: colors.black,
              borderRadius: 14,
            }}
          >
            <Text
              style={[
                isMobile ? fonts.smallMediumText_bold : fonts.mediumText_bold,
                { color: colors.black, paddingRight: 4 },
              ]}
            >
              reset
            </Text>
            <ResetIcon height={16} width={16} />
          </TouchableOpacity>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: colors.LightGrayishYellow,
    paddingBottom: 16,
    paddingLeft: 16,
    marginTop: 0,
  },
  footerContainer: {
    backgroundColor: colors.LightGrayishYellow,
    elevation: 10,
    padding: 15,
    borderTopWidth: 0.5,
    borderTopColor: colors.gray81,

    position: "absolute",
    alignItems: "center",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "row",
  },
  textInput: {
    borderWidth: 0.5,
    borderRadius: 10,
    borderColor: colors.gray81,
    backgroundColor: "white",
    flex: 1,
    // marginLeft: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    outlineStyle: "none",
  },
});
