import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Image,
  Linking,
  Pressable,
  ScrollView,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { colors } from "@gadder/common/src/styles/colors";
import { fonts } from "@gadder/common/src/styles/fonts";
import {
  GRADIENT_ANGLE,
  PlaceCard,
  priceHandler,
  TagsWithDivider,
  usePlaceTags,
} from "@gadder/common/src/components/PlaceCard";
import { ReactComponent as ArrowLeftWhite } from "@gadder/common/src/assets/svg/arrow_short_light_left.svg";
import { CommonButton } from "@gadder/common/src/components/buttons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  placeGetAction,
  placeGetBookingDataAction,
} from "../store/place/action";
import useUniqueDispatch, {
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../store/root-reducer";
import { ReactComponent as MapPinIcon } from "@gadder/common/src/assets/svg/map_black.svg";
import { ReactComponent as InstagramIcon } from "@gadder/common/src/assets/svg/instagram_light.svg";
import { PhotoCarousel } from "@gadder/common/src/components/PhotoCarousel";
import { FullPlaceType } from "@gadder/common/src/api/custom-models/place";
import { ShareButton } from "@gadder/common/src/components/ShareButton";
import copy from "copy-to-clipboard";
import { ORIGIN_BASE_URL } from "../common/constants";
import { InputSelect } from "../components/InputSelect";
import { DatePicker, getBookingMinDate } from "../components/DatePicker";
import { format } from "date-fns";
import { times } from "lodash";
import differenceInMinutes from "date-fns/differenceInMinutes";

export const PlacePage = () => {
  const [getPlaceToken, getPlace] = useUniqueDispatch(placeGetAction.request);

  const placeRequest = useSelector((store: RootState) => store.place);

  const { placeId } = useParams();

  const isPlaceLoading = isLoading(placeRequest, getPlaceToken);

  useEffect(() => {
    getPlace([placeId]);
  }, [placeId]);

  const place: FullPlaceType | null = useMemo(
    () => placeRequest?.data || null,
    [placeRequest?.data]
  );

  const filteredPictures = useMemo(
    () =>
      !place
        ? []
        : [place.picture_1, place.picture_2, place.picture_3].filter(Boolean),
    [place]
  );

  const placeTags = usePlaceTags(place);

  const shareLinkHandle = (place_id: string) => {
    copy(ORIGIN_BASE_URL + "/place/" + place_id);
  };

  const [bookMenuOpen, setBookMenuOpen] = useState(false);

  useEffect(() => {
    if (bookMenuOpen) {
      scrollRef.current?.scrollToEnd({ animated: true });
    }
  }, [bookMenuOpen]);

  const scrollRef = useRef<ScrollView>();

  const mapRouteHandle = () => {
    window.open(
      "https://www.google.com/maps?q=" +
        place?.latitude +
        "," +
        place?.longitude,
      "_blank"
    );
  };

  return (
    <View
      style={{
        width: Dimensions.get("window").width,
        height: Dimensions.get("window").height,
        backgroundColor: colors.gray_f9,
        alignItems: "center",
      }}
    >
      <View
        style={{
          height: "100%",
          maxWidth: 520,
          width: "100%",
        }}
      >
        {isPlaceLoading ? (
          <ActivityIndicator
            color={colors.black}
            style={{ flex: 1, height: "100%" }}
          />
        ) : !place ? (
          <View style={{ flex: 1, height: "100%", justifyContent: "center" }}>
            <Text
              style={{
                color: colors.black,
                textAlign: "center",
              }}
            >
              Error
            </Text>
          </View>
        ) : (
          <>
            <ScrollView
              ref={scrollRef}
              contentContainerStyle={{ paddingBottom: 90 }}
            >
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  marginTop: 12,
                }}
              >
                <Text
                  style={[
                    fonts.largeText_bold,
                    {
                      color: colors.black,
                      textAlign: "center",
                      marginBottom: 4,
                    },
                  ]}
                >
                  {place.name}
                </Text>

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: 12,
                    paddingHorizontal: 24,
                  }}
                >
                  <MapPinIcon />
                  <Text
                    style={[
                      fonts.smallMediumText_medium,
                      { color: colors.black, marginLeft: 4 },
                    ]}
                  >
                    {place.district + " | " + place.address}
                  </Text>
                </View>

                <View style={{ position: "relative", marginBottom: 12 }}>
                  <PhotoCarousel
                    overlay
                    containerStyle={{
                      height: 470,
                      width: "100%",
                      borderRadius: 0,
                    }}
                    data={filteredPictures}
                  />

                  <View
                    style={{
                      position: "absolute",
                      bottom: 24,
                      left: 10,
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <InstagramIcon />
                    <Text
                      onPress={() => Linking.openURL(place.instagram_url)}
                      style={[
                        fonts.smallMediumText_semiBold,
                        { color: colors.LightGrayishYellow, marginLeft: 8 },
                      ]}
                    >
                      {"@" + place.instagram_handler}
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    paddingHorizontal: 24,
                    marginBottom: 18,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text
                      numberOfLines={1}
                      style={[
                        fonts.smallMediumText_semiBold,
                        { color: colors.black },
                      ]}
                    >
                      {priceHandler(place.price)}
                    </Text>
                    <TagsWithDivider
                      dividerStyle={{ backgroundColor: colors.black }}
                      textStyle={{
                        color: colors.black,
                        ...fonts.smallMediumText_medium,
                      }}
                      tags={placeTags}
                    />
                  </View>

                  <ShareButton
                    iconHeight={28}
                    iconWidth={28}
                    toolTipTextStyle={fonts.smallText_semiBld}
                    onPress={() => shareLinkHandle(place.place_id)}
                  />
                </View>

                <Text
                  style={{
                    paddingHorizontal: 24,
                    color: colors.black,
                    ...fonts.mediumText_regular,
                  }}
                >
                  {place.description}
                </Text>
              </View>
              {bookMenuOpen && (
                <BookingSearchCard
                  place={place}
                  style={{ marginHorizontal: 12, marginTop: 12 }}
                />
              )}
            </ScrollView>
            <View
              style={{
                width: "100%",
                position: "absolute",
                bottom: 0,
                backgroundColor: colors.gray_f9,
                borderColor: "rgba(190, 188, 192, 0.5)",
                borderWidth: 1,
                flexDirection: "row",
                justifyContent: "center",
                paddingVertical: 10,
              }}
            >
              <CommonButton
                onPress={() => Linking.openURL(place?.menu)}
                textStyle={styles.menuButtonText}
                containerStyle={styles.menuButton}
                title={"Menu"}
              />
              <CommonButton
                onPress={() => setBookMenuOpen((prevState) => !prevState)}
                style={{ marginHorizontal: 12 }}
                textStyle={[
                  styles.menuButtonText,
                  bookMenuOpen && { color: colors.gray_f9 },
                ]}
                containerStyle={[
                  styles.menuButton,
                  bookMenuOpen && {
                    backgroundColor: colors.black,
                  },
                ]}
                title={"Book"}
              />
              <CommonButton
                onPress={mapRouteHandle}
                textStyle={styles.menuButtonText}
                containerStyle={styles.menuButton}
                title={"Direction"}
              />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const BookingSearchCard = ({
  place,
  style,
}: {
  style?: StyleProp<ViewStyle>;
  place: FullPlaceType;
}) => {
  const [bookingSearchParams, setBookingSearchParams] = useState({
    personsCount: "2",
    date: getBookingMinDate(new Date()),
  });

  const [getBookingDataToken, getBookingData] = useUniqueDispatch(
    placeGetBookingDataAction.request
  );

  const searchHandler = () => {
    getBookingData([
      {
        params: {
          id: place.place_id,
          date: format(bookingSearchParams.date, "Y-MM-dd"),
          time: format(bookingSearchParams.date, "HH:mm"),
          personsCount: bookingSearchParams.personsCount.replace("+", ""),
        },
      },
    ]);
  };
  const placeRequest = useSelector((store: RootState) => store.place);

  const isSearching = isLoading(placeRequest, getBookingDataToken);

  const [bookingTimes, setBookingTimes] = useState(null);

  useEffect(() => {
    setBookingTimes(
      place.book_provider && place.booking_dates ? place.booking_dates : null
    );
  }, [place.booking_dates]);

  useEffect(() => {
    setBookingTimes(null);
  }, [bookingSearchParams]);

  return (
    <View
      style={[
        {
          backgroundColor: colors.white,
          borderRadius: 10,
          paddingTop: 12,
          paddingBottom: 32,

          shadowOpacity: 0.25,
          shadowRadius: 10,
          shadowOffset: {
            width: 0,
            height: 4,
          },
          shadowColor: "#000000",
        },
        style,
      ]}
    >
      <Text
        style={[
          fonts.largeText_extraBold,
          { color: colors.black, textAlign: "center", marginBottom: 18 },
        ]}
      >
        Make a booking
      </Text>

      <View
        style={{
          flexDirection: "row",
          height: 47,
          marginBottom: 18,
          paddingHorizontal: 12,
        }}
      >
        <InputSelect
          menuStyle={{ top: "unset", bottom: 60 }}
          iconContainerStyle={{ top: 11 }}
          value={bookingSearchParams.personsCount}
          style={{ marginRight: 8, height: "100%" }}
          onChange={(_count) =>
            setBookingSearchParams((prevState) => ({
              ...prevState,
              personsCount: _count,
            }))
          }
        />

        <DatePicker
          dateButtonStyle={{
            paddingVertical: "unset",
            height: "100%",
            justifyContent: "center",
          }}
          style={{ flex: 1, alignSelf: "stretch" }}
          date={bookingSearchParams.date}
          calendarStyle={{ top: "unset", bottom: 60, left: 0 }}
          menuStyle={{ top: "unset", bottom: 60 }}
          setDate={(_date) =>
            setBookingSearchParams((prevState) => ({
              ...prevState,
              date: _date,
            }))
          }
        />
      </View>

      {isSearching ? (
        <ActivityIndicator
          color={colors.black}
          style={{ width: "100%", marginBottom: 18 }}
        />
      ) : bookingTimes ? (
        <View style={{ marginBottom: 18 }}>
          {place.book_provider !== "none" && place.booking_dates.length > 0 ? (
            <FlatList
              horizontal
              showsHorizontalScrollIndicator={false}
              data={place.booking_dates}
              contentContainerStyle={{
                gap: 4,
                paddingHorizontal: 12,
              }}
              renderItem={({ item }) => (
                <TouchableOpacity
                  onPress={() => {
                    window.open(place.book_url, "_blank");
                  }}
                  style={styles.bookingTime}
                >
                  <Text style={styles.bookingTimeLabel}>{item}</Text>
                </TouchableOpacity>
              )}
            />
          ) : (
            <Text
              style={{
                paddingHorizontal: 12,
                color: colors.black,
                ...fonts.mediumText_semiBold,
              }}
            >
              No booking available
            </Text>
          )}
        </View>
      ) : (
        <></>
      )}

      <CommonButton
        onPress={searchHandler}
        style={{ paddingHorizontal: 12 }}
        containerStyle={{
          width: "100%",
          paddingVertical: 12,
          borderRadius: 10,
          backgroundColor: colors.newBlue,
          borderWidth: 0,
        }}
        textStyle={[fonts.smallMediumText_bold, { color: colors.white }]}
        title={"Search"}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  menuButton: {
    backgroundColor: colors.white,
    borderRadius: 10,
    borderColor: "rgba(190, 188, 192, 0.5)",
    width: 100,
    paddingVertical: 15,
    paddingHorizontal: 0,
  },
  menuButtonText: { ...fonts.smallText_bold, color: colors.black },
  bookingTime: {
    padding: 8,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: colors.gray81,
  },
  bookingTimeLabel: {
    color: colors.black,
    ...fonts.smallText_semiBld,
  },
});
