import React, { useEffect, useMemo, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  FlatList,
  ActivityIndicator,
  Pressable,
  Linking,
} from "react-native";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "@gadder/common/src/assets/icons/ArrowLeft";
import { Settings } from "@gadder/common/src/assets/icons/Settings";
import {
  WINDOW_HEIGHT,
  useIsDesktop,
} from "@gadder/common/src/utils/useIsDesktop";
import useUniqueDispatch, {
  isData,
  isLoading,
  useSelector,
} from "@gadder/common/src/utils/redux-utils";
import {
  SubscriptionCard,
  SubscriptionType,
} from "../components/SubscriptionCard";
import { RootState } from "../store/root-reducer";
import {
  userDeleteMeAction,
  userGetMyPaymentAction,
  userLogoutAction,
} from "../store/user/action";
import { fonts } from "@gadder/common/src/styles/fonts";
import { colors } from "@gadder/common/src/styles/colors";
import { EditProfile } from "../components/EditProfile";
import { UserAvatar } from "@gadder/common/src/components/user-avatar";
import { Header } from "../components/Header";
import { useIsMobile } from "./ChatPage";
import { SideMenuTabs } from "../components/chat/sideMenu";
import { ReactComponent as WhatsAppIcon } from "@gadder/common/src/assets/svg/whatsapp_icon_2.svg";
import { chatbotUserDataGetChatbotUserDataAction } from "../store/chatbotUserData/action";
const whatsappUrl = "https://wa.me/message/FUCMIB64LMCSO1";

export const ProfilePage = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const source = state?.source;

  const isMobile = useIsMobile();

  const user = useSelector((store: RootState) => store.user);

  const [showEdit, setShowEdit] = useState(false);

  const chatbotUserDataRequest = useSelector(
    (store: RootState) => store.chatbotUserData
  );

  console.log("chatbotUserDataRequest", chatbotUserDataRequest.data);

  const [getChatbotUserDataToken, getChatbotUserData] = useUniqueDispatch(
    chatbotUserDataGetChatbotUserDataAction.request
  );

  useEffect(() => {
    getChatbotUserData([]);
  }, []);

  const isChatbotUserDataLoading = isLoading(
    chatbotUserDataRequest,
    getChatbotUserDataToken
  );

  const chatbotUserData = isData(
    chatbotUserDataRequest,
    getChatbotUserDataToken
  );

  const [logoutToken, logout] = useUniqueDispatch(userLogoutAction.request);
  const [deleteMeToken, deleteMe] = useUniqueDispatch(
    userDeleteMeAction.request
  );

  const onDeleteEdit = () => {
    deleteMe([{}]);
    navigate("../", { replace: true });
  };

  const sourceRouteHandler = (source: SideMenuTabs) => {
    switch (source) {
      case SideMenuTabs.CHATS:
        return "bot";
      case SideMenuTabs.MAP:
        return "map";
      case SideMenuTabs.PLACELIST:
        return "placelists";
      default:
        return "";
    }
  };

  const backRoute = () => {
    if (source) {
      navigate("/" + sourceRouteHandler(source));
    } else {
      navigate("/");
    }
  };

  const subscribeHandler = () => {
    window.open(
      process.env.REACT_APP_BASE_PATH +
        "/api/chatbot/checkout/" +
        chatbotUserDataRequest.data.id,
      "_blank"
    );
  };

  return (
    <View style={[styles.screen]}>
      <Header
        containerStyle={[{ maxWidth: 790 }, isMobile && { paddingLeft: 21 }]}
        onPressRight={() => {
          logout([{}]);
          navigate("../", { replace: true });
        }}
        rightButton={
          // isMobile && (
          <Text style={[fonts.mediumText_regular, { color: colors.black }]}>
            Exit
          </Text>
          // )
        }
        leftButton={
          <TouchableOpacity
            onPress={backRoute}
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <ArrowLeft style={{ marginRight: 4 }} />

            <Text
              style={[fonts.mediumText_regular, { color: colors.noteGray }]}
            >
              back
            </Text>
          </TouchableOpacity>
        }
      />

      <ScrollView
        style={styles.verticalScroll}
        contentContainerStyle={{ alignItems: "center" }}
      >
        {showEdit ? (
          <EditProfile
            onCancel={() => setShowEdit(false)}
            onSave={() => setShowEdit(false)}
            onDelete={() => onDeleteEdit()}
          />
        ) : (
          <View
            style={[
              styles.upperContainer,
              !isMobile && { paddingHorizontal: 0 },
            ]}
          >
            <View style={styles.userContainer}>
              <UserAvatar
                user={user?.data}
                style={{ marginRight: 12 }}
                size={82}
              />
              <View>
                <Text
                  style={[fonts.mediumText_medium, { color: colors.black }]}
                >
                  {user?.data?.first_name}
                </Text>
                <Text
                  style={[fonts.mediumText_medium, { color: colors.black }]}
                >
                  {user?.data?.second_name}
                </Text>
              </View>
            </View>
            <TouchableOpacity
              onPress={() => {
                setShowEdit(true);
              }}
            >
              <Settings style={{ marginLeft: 12 }} />
            </TouchableOpacity>
          </View>
        )}

        <Pressable
          onPress={() => Linking.openURL(whatsappUrl)}
          style={{
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 20,
            marginBottom: 24,
            paddingHorizontal: 10,
            backgroundColor: "rgba(0, 160, 0, 0.2)",
            borderWidth: 1,
            borderColor: "rgba(0, 160, 0, 0.8)",
            borderRadius: 28,
            flexDirection: "row",
          }}
        >
          <WhatsAppIcon height={40} width={40} />

          <Text style={[{ color: colors.black }, fonts.smallMediumText_medium]}>
            Open to speak with{"\n"}our AI concierge
          </Text>
        </Pressable>

        <View
          style={[
            styles.offersTitleContainer,
            !isMobile && { paddingHorizontal: 0, flexDirection: "row" },
          ]}
        >
          <Text style={[fonts.mediumLargeText_bold, { color: colors.black }]}>
            Subscriptions
          </Text>
        </View>

        {isChatbotUserDataLoading ? (
          <ActivityIndicator color={colors.black} style={{ flex: 1 }} />
        ) : (
          chatbotUserData && (
            <FlatList
              style={isMobile && { width: "100%" }}
              contentContainerStyle={[
                styles.offers,
                !isMobile && styles.offers_desktop,
              ]}
              data={isMobile ? subscriptionsList.reverse() : subscriptionsList}
              CellRendererComponent={({ index, style, ...props }) => (
                <View
                  index={index}
                  style={{ height: isMobile ? "unset" : "100%" }}
                  {...props}
                />
              )}
              horizontal={!isMobile}
              renderItem={({ item }) => (
                <SubscriptionCard
                  paymentData={chatbotUserData}
                  subscription={item}
                  containerStyle={{
                    height: isMobile ? "unset" : "100%",
                    marginHorizontal: isMobile ? 0 : 22,
                  }}
                  onSubscribe={subscribeHandler}
                />
              )}
            />
          )
        )}

        <Text style={[fonts.mediumText_medium, { color: colors.black }]}>
          Contact us: hello@gadderapp.com
        </Text>
      </ScrollView>
    </View>
  );
};

const subscriptionsList = [
  {
    title: "Starter",
    type: "start",
    price: null,
    features: [
      {
        available: false,
        name: "Unlimited access to WhatsApp assistant bot",
      },
      {
        available: false,
        name: "Personalised results",
      },
      {
        available: false,
        name: "Access to Beta features as they launch - voice note request, all types of activities and more",
      },
      {
        available: false,
        name: "Priority Support",
      },
    ],
  },
  {
    title: "Pro monthly",
    type: "premium",
    price: "£4.49",
    features: [
      {
        available: true,
        name: "Unlimited access to WhatsApp assistant bot",
      },
      {
        available: true,
        name: "Personalised results",
      },
      {
        available: true,
        name: "Access to Beta features as they launch - voice note request, all types of activities and more",
      },
      {
        available: true,
        name: "Priority Support",
      },
    ],
  },
];

const styles = StyleSheet.create({
  screen: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: WINDOW_HEIGHT,
  },
  verticalScroll: {
    paddingBottom: 20,
    width: "100%",
  },
  userContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 0,
  },
  upperContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 790,
    width: "100%",
    paddingHorizontal: 16,
    marginBottom: 24,
  },
  offersTitleContainer: {
    flexDirection: "column-reverse",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 790,
    width: "100%",
    paddingHorizontal: 16,
  },
  offers: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 12,
    paddingTop: 24,
    paddingHorizontal: 16,
  },
  offers_desktop: {
    paddingHorizontal: 0,
    flexDirection: "row",
  },
  activeSubscriptionsContainer: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 790,
    paddingHorizontal: 16,
  },
  activeSubscriptionsContainer_desktop: {
    paddingHorizontal: 0,
    alignItems: "flex-start",
  },
});
